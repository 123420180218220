import React from "react"
import styled from "styled-components"
import Header from "../components/header"
import Reveal from "react-reveal/Reveal"
import Footer from "../components/footer"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { INLINES } from "@contentful/rich-text-types"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"

const website_url = "https://youchangeearth.org"
const options = {
  renderText: text =>
    text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
  renderNode: {
    [INLINES.HYPERLINK]: node => {
      return (
        <a
          href={node.data.uri}
          target={`${
            node.data.uri.startsWith(website_url) ? "_self" : "_blank"
          }`}
          rel={`${
            node.data.uri.startsWith(website_url) ? "" : "noopener noreferrer"
          }`}
          style={{ textDecoration: "none", color: "var(--green)" }}
        >
          {node.content[0].value}
        </a>
      )
    },
  },
}

const BackgroundContainer = styled.div`
  background-color: #030d2270;
  color: white;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 6em 1em 4em 1em;
  color: white;
  @media (min-width: 425px) {
    padding: 10em 2em 10em 2em;
  }
  @media (min-width: 768px) {
    padding: 6em 14em 6em 14em;
    display: block;
  }
  @media (min-width: 1440px) {
    padding: 8em 20em 8em 20em;
  }
  @media (min-width: 2000px) {
    padding: 8em 40em 8em 40em;
  }
`

const FlipOrderImg = styled(Img)`
  order: 1;
  @media (min-width: 768px) {
    order: initial;
  }
`

const FlipOrderDiv = styled.div`
  order: 2;
  @media (min-width: 768px) {
    order: initial;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: 4rem 1rem;
  row-gap: 3rem;
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: center;
  padding-bottom: 1rem;
  @media (min-width: 768px) {
    display: grid;
    padding: 4rem 2rem;
    padding-bottom: 1rem;
    row-gap: 5rem;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
  }
`

const DescriptionContainer = styled.div`
  padding: 4rem 1rem;
  display: flex;
  justify-content: center;
  @media (min-width: 768px) {
    padding: 4rem 15%;
  }
`

const QuantifyingImpact = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulQuantifyingImpactItems(sort: { fields: order }) {
        nodes {
          header
          subtitle
          description {
            json
          }
          image {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  `)
  if (data) {
    const items = data.allContentfulQuantifyingImpactItems.nodes

    return (
      <div>
        <SEO title="Quantifying Impact" />

        <Reveal>
          <BackgroundImage
            fluid={[
              `linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0),
                rgba(0, 0, 0, 0.3)
              )`,
              items[0].image.fluid,
            ]}
            alt="Header Image"
            style={{
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <Header logoColor="white" textColor="white" />
            <h1
              style={{
                textAlign: "center",
                fontSize: "1.8rem",
                padding: "2rem",
                paddingTop: "10rem",
                color: "white",
              }}
            >
              <b>{items[0].header}</b>
            </h1>
          </BackgroundImage>

          <DescriptionContainer>
            <p
              style={{
                marginTop: "1rem",
                textAlign: "center",
              }}
            >
              {documentToReactComponents(items[0].description.json, options)}
            </p>
          </DescriptionContainer>
          <BackgroundImage
            fluid={items[1].image.fluid}
            alt="Image 2"
            style={{
              backgroundPosition: "center",
            }}
          >
            <div style={{ height: "30rem" }} />
          </BackgroundImage>
          <div
            style={{
              paddingTop: "4rem",
              paddingBottom: "2rem",
            }}
          >
            <DescriptionContainer style={{ paddingTop: 0, paddingBottom: 0 }}>
              <div>
                <h3 style={{ fontSize: "1.5rem", marginBottom: "1rem" }}>
                  {items[1].header}
                </h3>
                <p>
                  {documentToReactComponents(
                    items[1].description.json,
                    options
                  )}
                </p>
              </div>
            </DescriptionContainer>
          </div>
          <BackgroundImage
            fluid={items[2].image.fluid}
            alt="Image 3"
            style={{
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <BackgroundContainer>
              <h3 style={{ fontSize: "1.75rem", textAlign: "center" }}>
                {items[2].header}
              </h3>
              <br />
              <p>
                {documentToReactComponents(items[2].description.json, options)}
              </p>
            </BackgroundContainer>
          </BackgroundImage>
          <ContentContainer>
            <Img
              fluid={items[3].image.fluid}
              alt="Impact 4"
              style={{
                borderRadius: "5px",
                width: "50%",
              }}
            />
            <div style={{ marginBottom: "1rem", padding: "2rem" }}>
              <h3 style={{ fontSize: "1.7rem", marginBottom: "1rem" }}>
                {items[3].header}
              </h3>
              {documentToReactComponents(items[3].description.json, options)}
            </div>
            <FlipOrderDiv style={{ padding: "2rem" }}>
              <h3 style={{ fontSize: "1.7rem", marginBottom: "1rem" }}>
                {items[4].header}
              </h3>
              {documentToReactComponents(items[4].description.json, options)}
            </FlipOrderDiv>
            <FlipOrderImg
              fluid={items[4].image.fluid}
              alt="Image 5"
              style={{
                borderRadius: "5px",
                width: "50%",
              }}
            />
          </ContentContainer>
          <div
            style={{
              paddingTop: "4rem",
              marginTop: "3rem",
              paddingBottom: "4rem",
              backgroundColor: "#d4e6c9",
            }}
          >
            <DescriptionContainer style={{ paddingTop: 0, paddingBottom: 0 }}>
              <div>
                <h3 style={{ fontSize: "1.5rem", marginBottom: "1rem" }}>
                  {items[5].header}
                </h3>
                <p>
                  {documentToReactComponents(
                    items[5].description.json,
                    options
                  )}
                </p>
              </div>
            </DescriptionContainer>
          </div>
          {/* 
          <div
            style={{
              textAlign: "center",
              backgroundColor: "#d4e6c9",
              paddingTop: "4rem",
              paddingBottom: "2rem",
            }}
          >
            <h3 style={{ fontSize: "1.75rem", marginBottom: "1rem" }}>
              {items[4].header}
            </h3>
            <DescriptionContainer style={{ paddingTop: 0, paddingBottom: 0 }}>
              <p>
                {documentToReactComponents(items[4].description.json, options)}
              </p>
            </DescriptionContainer>
          </div>
          <ContentContainer>
            <Img
              fluid={items[5].image.fluid}
              alt="Seals on shore"
              style={{
                borderRadius: "5px",
                width: "80%",
              }}
            />
            <div style={{ marginBottom: "1rem", padding: "2rem" }}>
              <h3 style={{ fontSize: "1.7rem", marginBottom: "1rem" }}>
                {items[5].header}
              </h3>
              {documentToReactComponents(items[5].description.json, options)}
            </div>

            <FlipOrderDiv style={{ padding: "2rem" }}>
              <h3 style={{ fontSize: "1.7rem", marginBottom: "1rem" }}>
                {items[6].header}
              </h3>
              {documentToReactComponents(items[6].description.json, options)}
            </FlipOrderDiv>
            <FlipOrderImg
              fluid={items[6].image.fluid}
              alt="Turtle"
              style={{
                borderRadius: "5px",
                width: "80%",
                height: "70%",
              }}
            />
          </ContentContainer>
          <BackgroundImage
            fluid={items[7].image.fluid}
            alt="Bears in woods"
            style={{
              backgroundPosition: "center",
            }}
          >
            <div style={{ height: "30rem" }} />
          </BackgroundImage>
          <div style={{ padding: "5rem 1rem", textAlign: "center" }}>
            <h3 style={{ fontSize: "1.75rem" }}>{items[7].header}</h3>
            <br />
            <DescriptionContainer
              style={{ paddingTop: 0, paddingBottom: "1rem" }}
            >
              <p>
                {documentToReactComponents(items[7].description.json, options)}
              </p>
            </DescriptionContainer>
          </div> */}
        </Reveal>
        <Footer />
      </div>
    )
  }
}

export default QuantifyingImpact
